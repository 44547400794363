import './index.css';

let navToggle = document.querySelector('.nav-toggle')
let bars = document.querySelectorAll('.bar')
var navlinks = document.getElementById('nav-link');

function toggleHamburger(e) {
  bars.forEach(bar => bar.classList.toggle('x'))
  navlinks.classList.toggle('hidden');
}

navToggle.addEventListener('click', toggleHamburger)